import * as React from "react"
import styled from "styled-components"
import Layout from "../../../components/layout"
import Projects from "../../../components/pp_projects"
import { StaticImage } from "gatsby-plugin-image"
import Footer from '../../../components/footer';
import Wrapper from '../../../components/wrap';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Head from "../../../images/pp/head/od/chart.inline.svg";
import Bars from "../../../images/pp/head/od/bars.inline.svg";
import Bars2 from "../../../images/pp/head/od/bars2.inline.svg";
import Rates from "../../../images/pp/head/od/rates.inline.svg";

const IndexPage = () => {
  return (
    <Layout navtype="dark" title="Premium Parking">
      <Header>
        <div className="row">
          <div className="col col-l">
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
              <Bars />
            </AnimationOnScroll>
          </div>
          <div className="col">
            <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce="true">
              <Bars2 />
            </AnimationOnScroll>
          </div>
          <div className="col col-r">
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
              <Rates />
            </AnimationOnScroll>
          </div>
        </div>
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
          <Head className="chart" />
        </AnimationOnScroll>
        <Wrapper className="no-gaps">
          <h1>
            <small>Premium Parking</small>
            Shift the responsibility from operators to the parking lots owners </h1>
          <ul className="types">
            <li>
              <small>Timeframe</small>
              <br />
              Jan–Jun 2021
            </li>
            <li>
              <small>Role</small>
              <br />
              Lead Product Design
            </li>
          </ul>
          <br />
          <p>
            Operator Dashboard is panel for Premium Parking admins. Operator Dashboard allow managing agreements, terms, and parking policies, payment policies, billing and reporting, administrators, group managers and validation users, integrations, invoices, flexible virtual permits for access control, nested zones and custom parking products and more…
          </p>
        </Wrapper>
      </Header>
      <AnimationOnScroll animateIn="animate__wrapInUp" animateOnce="true">
      <Wrapper className="first">
        <h3>Historical context</h3>
        <p>The Operator Dashboard design was based on an outdated bootstrap version without any UX attendance, and it didn't reflect the new brand. Anyway, it still worked, but with some constant issues.</p>
        <p>Examples of the Operator Dashboard before the redesign.</p>
        <StaticImage
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../../images/pp/od_old.png"
          alt="Old version of Operator Dashboard"
        />
        <br/>
        <br/>
        <strong className="sub-title">The business problem</strong>
        <h3>Overcome bottleneck</h3>
        <p>
          Transfer the responsibility for filling in information, rates, and other details from Premium Parking operators to the owners of the parking lots.
          Previously, it was all done by Premium Parking operators, and this become a bottleneck.
          <br/>
          <AnchorLink to="#results">View Results</AnchorLink>
        </p>
        <strong className="sub-title">My role and the team</strong>
        <h3>Focusing on the underlying problems</h3>
        <p>
          My goal was to avoid jumping to solutions, instead, I was focusing on the underlying problems and insights that can give the team foundational knowledge to base the future solution on.
          As a member of the core team, I was the Lead Product Designer during the project and worked alongside a Product Director, Engineers, Project Managers, and C-Level management.
          <br/>
          I was responsible for defining the overall experience through mapping out the current journey, design ideation, iteration, prototyping, and crafting high fidelity mockups throughout all stages of the project.
        </p>
        <h3>Our approach</h3>
        <p>Initially, we had a request to develop new functionalities for the <Link to="/projects/premium-parking/pam">Client Portal</Link> (we developed it in 2017-2018). The Client Portal covers accounting and agreement management between Premium Parking with parking owners, and parking owners with tenants businesses. All asked for new functionalities for Client Portal have been previously developed in Operator Dashboard. Additionally, a flexible admin permissions management tool has been developed. Theoretically, we could invite clients into OD.
        </p>
        <ol>
          At the beginning, we saw two options:
          <li>Develop new functionality for the Client Portal (duplication).</li>
          <li>Tidy up Operator Dashboard, so we can invite clients with restricted permissions, and get rid of Client Portal.</li>
        </ol>
        <p>
          After an investigation session with the development team, we listed all pros/cons for each option from development and security side.
          <br/>
          The second option looked like the better choice, after a quick review and estimation of the lists.
        </p>

        <p>
          Luckily, we already had accumulated usage data for Operator Dashboard, Client Portal, and Business Account. Also, we already ran user research (Customer Development) and based on results this task was born.
        </p>
        <ol>
          So, the main design challenges:
          <li>How to motivate parking owners to fit Premium Parking ecosystem?</li>
          <li>How to do not break layout if no permissions for all data?</li>
          <li>How to translate well to mobile web if it only for desktops?</li>
        </ol>
        <p>
          Each page would consist of several blocks that are easily collapsible and responsive on any device. The meaning of a block is a grouping of similar fields.
        </p>
        <h3 id="results">Final deliverables</h3>
        <p>We worked through many iterations to land on this design. While it optimizes for a mobile screen, it still has many advantages in tablet and desktop viewports as well.</p>
      </Wrapper>
      <div className="screens-with-animations">
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <div className="screen">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/od_marketing.png"
              alt="od_marketing"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <div className="screen">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/od_overview.png"
              alt="od_overview"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <div className="screen">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/od_reporting.png"
              alt="od_reporting"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <div className="screen">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/od_rates_survey.png"
              alt="od_rates_survey"
            />
          </div>
        </AnimationOnScroll>
      </div>
      <div className="screens-grid">
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/od_m_marketing.png"
              alt="OD Marketing"
              className="mobile-screen"
            />
          </AnimationOnScroll>
        </div>
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/od_m_reporting.png"
              alt="OD Reporting"
              className="mobile-screen"
            />
          </AnimationOnScroll>
        </div>
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/od_m_staffing.png"
              alt="OD Staffing"
              className="mobile-screen"
            />
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/od_m_menu.png"
              alt="OD Staffing"
              className="mobile-screen"
            />
          </AnimationOnScroll>
        </div>
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/od_m_rates.png"
              alt="OD Staffing"
              className="mobile-screen"
            />
          </AnimationOnScroll>
        </div>
      </div>
    <Wrapper>
      <h3>Next steps</h3>
      <p>This project happened while the core Premium Platform was being re-configured. The next steps would be to speak with clients and validate with data, and integrate new features with other products. Additionally, it should undergo more rigorous user testing with customers for more in-depth validation of features.</p>
    </Wrapper>
    <Projects project="od" />
    </AnimationOnScroll>
  <Footer />
</Layout>
  )
}

const Header = styled.div`
  background: #F2F2F2;

  .no-gaps {
    padding-bottom: 10px;
    margin-bottom: 80px;
  }

  svg {
    max-width: 100%;
    height: auto;
  }

  .row {
    display: flex;
    column-gap: 30px;
    justify-content: space-between;
    padding: 1rem 1rem 0;
    margin-bottom: -1rem;
  }

  h2, p {
    color: #000;

    .dark & {
      color: #fff;
    }
  }

  @media (max-width: 675px) {
    margin-bottom: 30px;
    .row {
      margin-bottom: -2rem;
      position: relative;
      column-gap: 20px;
    }
  }

  @media (max-width: 560px) {
    overflow: hiddne;

    .col-l {
      display: none;
    }

    .col-r {
      margin-right: -50%;
    }

    h2 {
      margin-top: var(--spacing-6);
    }
  }

  .dark & {
    background: rgba(231, 231, 231, .1);
  }

  .lines {
    .dark & {
      opacity: .1;
    }
  }

  .black {
    .dark & {
      fill: #fff;
    }
  }

  .chart {
    width: 100%;
  }
`
export default IndexPage
